<template>
  <div class="form-user">
    <!-- ユーザーアイコン画像 -->
    <label :class="[isInvalidStatus('icon') ? 'form-user__label--warning' : 'form-user__label']">プロフィール画像
      <common-required class="form-user__label__required" /><span v-if="isInvalidStatus('icon')" class="form-user__label__error">プロフィール画像を設定してください</span></label>
    <label :class="[isInvalidStatus('icon') ? 'form-user__upload--warning' : 'form-user__upload']">
      <img class="form-user__upload__img" v-if="icon" :src="icon" />
      <div class="form-user__upload__cover">
        <v-icon class="form-user__upload__cover__icon">camera_alt</v-icon>
      </div>
      <input class="form-user__upload__input"
             @change="selectPicture" type="file" accept=".png, .jpg, .jpeg, .gif" />
    </label>
    <!-- ユーザー名 -->
    <label :class="[isInvalidStatus('name') ? 'form-user__label--warning' : 'form-user__label']">お名前
      <common-required class="form-user__label__required" /><span v-if="isInvalidStatus('name')" class="form-user__label__error">{{this.nameValidationMessage}}</span></label>
    <input :class="[isInvalidStatus('name') ? 'form-user__input--warning' : 'form-user__input']" placeholder="名前やニックネーム" v-model="name" type="text" @input="emitData('name', name)" />
    <div class="form-user__warning">
      <span :class="{ 'form-user__warning__error' : isInvalidStatus('name') }">{{ name.length }}/{{ MAX_LENGTH['name'] }}</span>
    </div>
    <!-- 会社名・肩書など -->
    <label :class="[isInvalidStatus('position') ? 'form-user__label--warning' : 'form-user__label']">会社名・肩書など<span v-if="isInvalidStatus('position')" class="form-user__label__error">文字数がオーバーしています</span></label>
    <input :class="[isInvalidStatus('position') ? 'form-user__input--warning' : 'form-user__input']" placeholder="株式会社〇〇 CEO" v-model="position" type="text" @input="emitData('position', position)" />
    <div class="form-user__warning">
      <span :class="{ 'form-user__warning__error' : isInvalidStatus('position') }">{{ position.length }}/{{ MAX_LENGTH['position'] }}</span>
    </div>
    <!-- 自己紹介 -->
    <label :class="[isInvalidStatus('profile') ? 'form-user__label--warning' : 'form-user__label']">プロフィール<span v-if="isInvalidStatus('profile')" class="form-user__label__error">文字数がオーバーしています</span></label>
    <textarea :class="[isInvalidStatus('profile') ? 'form-user__textarea--warning' : 'form-user__textarea']" placeholder="活動内容・経歴・実績を書きましょう" v-model="profile" type="text" @input="emitData('profile', profile)" />
    <div class="form-user__warning">
      <span :class="{ 'form-user__warning__error' : isInvalidStatus('profile') }">{{ profile.length }}/{{ MAX_LENGTH['profile'] }}</span>
    </div>
    <!-- URL -->
    <label :class="[isInvalidStatus('link') ? 'form-user__label--warning' : 'form-user__label']">URL<span v-if="isInvalidStatus('link')" class="form-user__label__error">URLの形式が無効です</span></label>
    <input :class="[isInvalidStatus('link') ? 'form-user__input--warning' : 'form-user__input']" placeholder="ウェブサイト・ツイッター・インスタ" v-model="link" type="url" @input="emitData('link', link)" />
  </div>
</template>

<script>
import image from '@/assets/lib/image'
import url from '@/assets/lib/url'

import CommonRequired from '@/components/common/required'

export default {
  mixins: [image, url],
  components: { CommonRequired },
  props: {
    // ユーザー情報
    user: {
      type: Object
    },
    // 各入力項目のバリデーション結果
    validationStatus: {
      type: Object,
      default: () => {
        return {
          // プロフィール画像
          icon: {
            before: '',
            now: ''
          },
          // 名前
          name: {
            before: '',
            now: ''
          },
          // 会社名・肩書
          position: {
            before: '',
            now: ''
          },
          // プロフィール
          profile: {
            before: '',
            now: ''
          },
          // URL
          link: {
            before: '',
            now: ''
          }
        }
      }
    }
  },
  data () {
    return {
      // プロフィール画像
      icon: '',
      // 名前
      name: '',
      // 会社名・肩書
      position: '',
      // プロフィール
      profile: '',
      // URL
      link: ''
    }
  },
  mounted () {
    // ユーザー情報の格納
    Object.keys(this.$data).forEach(key => {
      if (this.user && this.user[key]) this.$data[key] = this.user[key]
      // 各入力項目のバリデーション結果の初期値をセットする
      this.$emit('set-nowvalidationstatus', key, this[`${key}ValidationStatus`])
    })

    // 変更可能かの初期状態を設定する
    this.$emit('set-canchange', this.isChanged)
  },
  computed: {
    /**
     * @return {String} ユーザーID
     */
    uid () {
      return this.$store.getters['auth/uid']
    },
    /**
     * @return {Object} 文字数の上限
     */
    MAX_LENGTH () {
      return this.$store.getters.MAX_LENGTH.USER
    },
    /**
     * @return {Object} バリデーション結果の定義
     */
    VALIDATION_DEFINITION () {
      return this.$store.getters.VALIDATION_DEFINITION
    },
    /**
     * iconのバリデーション結果
     * @return {String} VALIDATION_DEFINITIONのいずれかの値
     */
    iconValidationStatus () {
      if (this.icon) {
        return this.VALIDATION_DEFINITION['valid']
      } else {
        return this.VALIDATION_DEFINITION['requiredBlank']
      }
    },
    /**
     * nameのバリデーション結果
     * @return {String} VALIDATION_DEFINITIONのいずれかの値
     */
    nameValidationStatus () {
      if (this.name && this.name.length <= this.MAX_LENGTH['name']) {
        return this.VALIDATION_DEFINITION['valid']
      } else if (this.name.length > this.MAX_LENGTH['name']) {
        return this.VALIDATION_DEFINITION['overLength']
      } else {
        return this.VALIDATION_DEFINITION['requiredBlank']
      }
    },
    /**
     * 名前のバリデーション結果に対応するメッセージを返却する
     * @return {String} メッセージ
     */
    nameValidationMessage () {
      if (this.validationStatus.name.before === this.VALIDATION_DEFINITION['overLength']) {
        return '文字数がオーバーしています'
      } else if (this.validationStatus.name.before === this.VALIDATION_DEFINITION['requiredBlank']) {
        return '名前を入力してください'
      } else {
        return ''
      }
    },
    /**
     * positionのバリデーション結果
     * @return {String} VALIDATION_DEFINITIONのいずれかの値
     */
    positionValidationStatus () {
      if (this.position.length <= this.MAX_LENGTH['position']) {
        return this.VALIDATION_DEFINITION['valid']
      } else {
        return this.VALIDATION_DEFINITION['overLength']
      }
    },
    /**
     * profileのバリデーション結果
     * @return {String} VALIDATION_DEFINITIONのいずれかの値
     */
    profileValidationStatus () {
      if (this.profile.length <= this.MAX_LENGTH['profile']) {
        return this.VALIDATION_DEFINITION['valid']
      } else {
        return this.VALIDATION_DEFINITION['overLength']
      }
    },
    /**
     * linkのバリデーション結果
     * @return {String} VALIDATION_DEFINITIONのいずれかの値
     */
    linkValidationStatus () {
      if (!this.link || this.link.length <= 0 || this.isValidURL(this.link)) {
        return this.VALIDATION_DEFINITION['valid']
      } else {
        return this.VALIDATION_DEFINITION['typeInvalid']
      }
    },
    /**
     * @return {Boolean} 入力項目が変更されたかどうか
     */
    isChanged () {
      // 初回登録時はthis.userに登録された情報が無い
      return this.user ? Object.keys(this.$data).some(key => this.user[key] !== this.$data[key]) : true
    },
    /**
     * 指定した入力項目が無効になっていないかをチェックする
     * @param {String} key 入力項目名
     * @return {Boolean} 有効 or 無効
     */
    isInvalidStatus () {
      return key => this.validationStatus[key].before !== this.VALIDATION_DEFINITION['valid']
    }
  },
  methods: {
    /**
     * 画像の選択
     * @param {Object} event 発火したイベント
     */
    async selectPicture (event) {
      event.preventDefault()

      // 保存先URLパスとファイルの格納
      const file = event.target.files[0]

      const path = '/users/' + this.uid + '/icon/'
      const results = await this.doImgProcess(file, path)
      if (!results) return

      this.icon = results.url
      this.emitData('icon', results.url)
      this.$emit('set-blob', results.blob, results.path)
    },
    /**
     * 親コンポーネントへデータを渡す
     * @param {String} key
     * @param {String} value
     */
    emitData (key, value) {
      // 変更した値の格納
      this.$emit('set-postparams', key, value)

      // 値が初期値から変更されたかの結果をセットする
      this.$emit('set-canchange', this.isChanged)

      // 入力されている値の現在のバリデーション結果をセットする
      this.$emit('set-nowvalidationstatus', key, this[`${key}ValidationStatus`])
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/color.scss";
@import "@/assets/sass/size.scss";
@import "@/assets/sass/fontfamily.scss";

.form-user {
  &__upload {
    position: relative;
    display: block;
    width: 146px;
    height: 200px;
    margin-top: $unit_size;
    background-color: $gray_lighten_color;
    border-color: transparent;
    &__img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &__cover {
      position: absolute;
      top: 50%;
      left: 50%;
      display: flex;
      align-items: flex-start;
      transform: translate(-50%, -50%);
      &__icon {
        font-family: $material-outlined;
        object-fit: cover;
        &.v-icon {
          font-size: 4.5rem;
          color: $gray_color;
        }
      }
    }
    &__input {
      display: none;
    }
    &--warning {
      @extend .form-user__upload;
      border: 1px solid $red_color;
    }
  }
  &__label {
    display: block;
    margin-top: $unit_size * 2;
    font-size: 1.2rem;
    font-weight: bold;
    line-height: 1.5rem;
    &__required {
      margin-left: $unit_size;
    }
    &--warning {
      @extend .form-user__label;
      color: $red_color;
    }
    &__warning {
      color: $red_color;
    }
    &__error {
      margin-left: $unit_size;
      font-weight: normal;
      color: $red_color;
    }
  }
  &__input {
    width: 100%;
    max-width: $max_width;
    height: 28px;
    padding: $unit_size 0;
    margin-top: $unit_size;
    font-size: 1.6rem;
    line-height: 2rem;
    border-bottom: 1px solid $black_lighten_color;
    outline: 0;
    &::placeholder {
      color: $gray_color;
    }
    &--warning {
      @extend .form-user__input;
      border-bottom: 1px solid $red_color;
    }
  }
  &__warning {
    height: $unit_size * 2;
    font-size: 1rem;
    line-height: 1.6rem;
    text-align: right;
    &--center {
      @extend .form-user__warning;
      margin-top: $unit_size * 2;
      font-size: 1.2rem;
      text-align: center;
    }
    &__error {
      color: $red_color;
    }
  }
  &__textarea {
    display: block;
    width: 100%;
    height: 92px;
    padding: $unit_size 0;
    margin-top: $unit_size;
    overflow: scroll;
    font-size: 1.6rem;
    line-height: 2rem;
    resize: none;
    border-bottom: 1px solid $black_lighten_color;
    outline: none;
    &::placeholder {
      color: $gray_color;
    }
    &--warning {
      @extend .form-user__textarea;
      border-bottom: 1px solid $red_color;
    }
  }
}
</style>
