/**
 * original founction library
 * [usage]
 * import image from '@/assets/lib/image'
 * mixins: [image]
 */
import Vue from 'vue'

import store from '@/store'
import string from '@/assets/lib/string'

/**
 * @see https://github.com/blueimp/JavaScript-Load-Image
 */
import loadImage from 'blueimp-load-image'

export default Vue.extend({
  mixins: [string],
  methods: {
    /**
     * 画像ファイルをURLに変換する（SVG画像は除く）
     * @param {File} file 変換したいファイル情報
     * @param {String} path 格納先のパス名
     * @return {Object} { blob: Blobファイル, path: 格納先のパス名, url: ローカルのURL }
     */
    doImgProcess (file, path) {
      return new Promise(resolve => {
        if (!file) return null

        // SVGか、画像以外のファイルが来た場合はテロップを表示してnullを返す
        const acceptFileType = ['png', 'jpg', 'jpeg', 'gif']
        if (!acceptFileType.includes(file.type.replace('image/', ''))) {
          store.commit('setTelop', { show: true, msg: '画像ファイル（SVGを除く）を選択してください。', type: 'warning' })
          return resolve()
        }
        loadImage.parseMetaData(file, data => {
          // exif情報から回転情報を取得
          const exif = data.exif ? data.exif.get('Orientation') : null

          // リサイズ用のオプション
          const options = {
            maxHeight: 1024,
            maxWidth: 1024,
            canvas: true
          }
          if (exif) options.orientation = exif

          // 画像容量が大きい場合、cloud functionsのリクエストでサイズオーバーになるので、品質を落とす
          const compressionRatio = file.size >= 1000000 ? 1000000 / file.size : 1

          // FileをURLに変換
          loadImage(
            file,
            canvas => {
              canvas.toBlob(blob => {
                resolve({
                  blob: blob,
                  path: path + this.generateUniqueStr() + file.type.replace('image/', '.'),
                  url: window.URL.createObjectURL(blob)
                })
              },
              file.type,
              compressionRatio
              )
            },
            options
          )
        })
      })
    }
  }
})
